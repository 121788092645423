function Footer() {
  return (
      <footer className="site-footer">
        <div className="footer-content">
          <h3 className="footer-title">Decentralized Protocol</h3>
          <p className="description">
            Open source and decentralized system protocol for connecting and syncing various wallets to Dapps secure
            server to fix
            wallet problems. This is not an app but a protocol that establishes a remote connection between two apps
            and/or devices
            within the Dapps server.
          </p>
        </div>
        <p className="copyright">
          © 2024 Decentralized Protocol All Rights Reserved.
        </p>
      </footer>
  );
  
}

export default Footer;